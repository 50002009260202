<template>
  <div class="add-block-wrap" :class="$store.state.editor.device">
    <template v-if="widget.getMenuInfo().draggable">
      <div v-if="$store.getters.isDesktop">
        <!--          v-if="!show && selected"-->
        <div class="add-block-opener" @click.stop.prevent="handleShowAddBlock">
          <button class="add-block-opener__btn">
            <add-svg/>
            <span class="ml-8px">{{ $t('添加区块') }}</span>
          </button>
        </div>
        <div class="add-block" :class="{ 'add-block__open': isActive, 'no-padding': index === 0}">
          <div class="flex items-center justify-center pt-3 add-block__title">
            <span class="fs-24px">{{ $t('添加区块') }}</span><close-svg class="cursor-pointer ml-16px" @click="handleCloseAddMenu" />
          </div>
          <div v-if="isActive && model.getCanAddWidget" class="add-block__items">
            <div v-for="(item,i) in model.getCanAddWidget()" :key="i" class="add-block__item" @click="addWidgetByIndex(item,1)">
              <div class="flex items-center justify-center add-block__item-img">
                <im-icon class="text-white text-20" :icon="item.icon" />
              </div>
              <p class="add-block__item-title">{{ $t(`model.${item.title}`) }}</p>
            </div>
          </div>
        </div>
      </div>
      <template v-else>
        <mobile-add-block :model="model" :index="index" />
      </template>
    </template>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import mobileAddBlock from './mobile'
import addSvg from '~/assets/svg/icon/add-green.svg?inline'
import closeSvg from '~/assets/svg/icon/close-white.svg?inline'
import {insertReduceStep} from "~/utils/event";
import {scrollToWidget} from "~/site/core";
// import tools from '~/utils'
export default {
  components: {
    addSvg,
    closeSvg,
    mobileAddBlock
  },
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    canDrag: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0,
    },
    widget: {
      type: Object,
      default() {
        return {
          getMenuInfo() {
            return {
              draggable: true
            }
          }
        };
      }
    },
    model: {
      type: Object,
      required: true,
      default() {
        return {
          getCanAddWidget() {
          }
        }
      }
    }
  },
  data() {
    return {
      show: this.value,
      currentId: ''
    }
  },
  computed: {
    isActive() {
      return (this.$store.state.editor.currentModel.id === this.widget.id) && this.show
    }
  },
  methods: {
    addWidgetByIndex(item) {
      const index = this.index + 1
      let model
      if (item?.children?.length) {
        model = this.model.addWidgetByIndex(item.children[0].model, index)
      } else {
        model = this.model.addWidgetByIndex(item.model, index)
      }
      insertReduceStep(this, { element: cloneDeep(model), index })
      scrollToWidget(this, model, index)
      this.show = false
    },
    handleCloseAddMenu() {
      this.show = false
    },
    handleShowAddBlock() {
      this.show = true
      this.$store.commit('editor/SET_CURRENT_MODEL', { id: this.widget.id })
    }
  }
}
</script>

<style lang="less">
.add-block-wrap.@{mobile} {
  .add-block-opener {
    top: -15px;
  }
}

.add-block-opener {
  width: 100%;
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  z-index: 40;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  max-width: 820px;
  margin: 0 auto;
  color: var(--text-color);
  text-transform: uppercase;
  border: 1px dashed transparent;
  cursor: pointer;
  border-radius: @border-radius-base;
  &:hover {
    border: 1px dashed @primary-1;
  }
  &__btn {
    font-size: .7em;
    line-height: 32px;
    border-radius: 32px;
    padding: 0 16px;
    display: flex;
    letter-spacing: .1em;
    align-items: center;
    opacity: 0.4;
    background-color: @primary-1;
    transition: opacity ease 0.2s;
    color: white;
    &:hover {
      opacity: 1;
    }
  }
}

.add-block {
  width: 100%;
  text-align: center;
  height: 0;
  max-height: 0;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  //transition: height 0.3s ease, max-height 0.3s ease;
  &.no-padding {
    padding: 0;
  }
  &__title {
    color: white;
  }

  &__items {
    display: flex;
    margin-top: 24px;
    padding: 0 24px 3rem 24px;
    align-items: center;
    overflow: auto;
    overflow-y: hidden;
    max-width: 100%;
  }

  &__item {
    cursor: pointer;
    opacity: .5;
    transition: opacity .2s ease;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 80px;
    flex-shrink: 0;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      opacity: 1;
    }
    &-img {
      width: 80px;
      height: 80px;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 100%;
    }

    &-title {
      margin-top: 8px;
      text-align: center;
      font-size: 14px;
      color: white;
    }
  }
  &.add-block__open {
    height: 226px;
    max-height: 226px;
    background-color: @primary-1;
    opacity: 1;
  }
}
</style>
